import classNames from 'classnames';

import horizontalLineStyles from '~/styles/components/Utility/Layout/HorizontalLine.css';

export const links = () => [{ rel: 'stylesheet', href: horizontalLineStyles }];

type Props = {
  className?: string;
};

const HorizontalLine = ({ className }: Props) => {
  return <div className={classNames('horizontalLine', className)} />;
};

export default HorizontalLine;